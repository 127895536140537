import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { prettyBits } from "xbits";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatBytes(...args: Parameters<typeof prettyBits>): string {
  return prettyBits(...args);
}

export function assertExists<T>(
  val: T | null | undefined,
  message: string | Error = "val does not exist",
): asserts val is T {
  if (process.env.NODE_ENV === "development") {
    // throw error in the development mode
    if (val === null || val === undefined) {
      if (message instanceof Error) {
        throw message;
      }
      throw new Error(message);
    }
  }
}

export const POLLING_INTERVAL = 1000;

export const MAX_TIMEOUT = 10_000;

const dateFormatter = Intl.DateTimeFormat(undefined, {
  dateStyle: "long",
});
const timeFormatter = Intl.DateTimeFormat(undefined, {
  timeStyle: "short",
});

export function formatDateTime(date: Date) {
  return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
}

export function addSeconds(date: Date, seconds: number) {
  return new Date(date.getTime() + seconds * 1000);
}

export function getBackendUrl() {
  return new URL(process.env.NEXT_PUBLIC_BACKEND_URL as string).origin;
}

export function getRetrieveUrl(pipelineId: string) {
  return getBackendUrl() + `/api/pipeline/${pipelineId}/retrieve`;
}

export function getQueryParams(
  searchParams: URLSearchParams,
): Record<string, string> {
  return Object.fromEntries(searchParams.entries());
}

export function formatQueryString(queryParams: Record<string, string>) {
  return new URLSearchParams(queryParams).toString();
}

export async function processPromisesBatch<T, R>(
  items: T[],
  limit: number,
  fn: (item: T) => Promise<R>,
): Promise<R[]> {
  let results: R[] = [];

  for (let start = 0; start < items.length; start += limit) {
    const end = start + limit > items.length ? items.length : start + limit;

    const slicedResults = await Promise.all(items.slice(start, end).map(fn));

    results = [...results, ...slicedResults];
  }

  return results;
}

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export function isValidJSON(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

// Converts an schema object into string and keeps the order of the fields
// Add space like JSON.stringify(obj, null, 2)
export function stringifySchema(
  obj: any = {},
  depth: number = 0,
  required: string[] = [],
): string {
  const indent = "  ".repeat(depth);
  if (obj === undefined) {
    return "";
  } else if (Array.isArray(obj)) {
    // For arrays, map each item through stringifySchema
    return `[
${indent}  ${obj.map((item) => stringifySchema(item, depth + 1)).join(`,\n${indent}  `)}
${indent}]`;
  } else if (obj !== null && typeof obj === "object") {
    // For objects, sort the keys and recurse
    const keys = Object.keys(obj).sort(
      (a, b) => (required.indexOf(a) ?? -1) - (required.indexOf(b) ?? -1),
    );
    return `{
${indent}  ${keys
      .filter((key) => obj[key] !== undefined)
      .map((key) => {
        let required = [];
        if (key === "properties") {
          required = obj.required ?? []; // add required from parent
        }
        return `"${key}": ${stringifySchema(obj[key], depth + 1, required)}`;
      })
      .join(`,\n${indent}  `)}
${indent}}`;
  } else {
    // For everything else (primitives), use standard JSON stringify
    return JSON.stringify(obj);
  }
}
